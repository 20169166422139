<template>
    <section class="custom-scroll overflow-auto pr-3" style="height:calc(100vh - 95px)">
        <div class="row mx-0 align-items-center">
            <i class="icon-back f-20 text-general cr-pointer" @click="$router.back()" />
            <p class="col-auto px-2 text-general f-18">
                Editar producto
            </p>
            <button type="button" class="btn-general cr-pointer px-3 ml-auto" @click="$router.back()">
                Actualizar
            </button>
        </div>
        <div class="bg-white pb-3 mt-3 br-12 pl-5 pt-5" style="box-shadow: 0px 1px 4px #00000014;">
            <p class="text-general f-17 f-600 mb-3">
                Datos generales
            </p>
            <div class="row mx-0 mt-3">
                <div class="col-3 pl-0">
                    <small class="pl-3 text-general">
                        Nombre del producto
                    </small>
                    <el-input v-model="productoTemp.nombre" size="small" class="w-100" placeholder="Nombre del producto" maxlength="60" @change="updateGeneral($event,'nombre',true)" />
                </div>
                <div class="col-3">
                    <small class="pl-3 text-general">
                        Presentación
                    </small>
                    <el-input v-model="productoTemp.presentacion.presentacion" size="small" class="w-100" placeholder="Presentacion" maxlength="25" @change="updateGeneral($event,'presentacion',true)" />
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-3 pl-0">
                    <small class="pl-3 text-general">
                        SKU
                    </small>
                    <el-input v-model="productoTemp.sku" size="small" class="w-100" placeholder="Nombre del producto" maxlength="20" @change="updateGeneral($event,'sku')" />
                </div>
                <div class="col-3">
                    <small class="pl-3 text-general">
                        BarCode
                    </small>
                    <el-input v-model="productoTemp.barcode.barcode" size="small" class="w-100" placeholder="Presentacion" maxlength="30" @change="updateGeneral($event,'barcode',true)" />
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-6 pl-0">
                    <small class="pl-3 text-general">
                        Descripción
                    </small>
                    <el-input v-model="productoTemp.descripcion" type="textarea" :rows="4" size="small" class="w-100" maxlength="500" placeholder="Descripción" @change="updateGeneral($event,'descripcion')" />
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-3 pl-0">
                    <small class="pl-3 text-general">
                        Unidad de medida
                    </small>
                    <el-select v-model="unidadMedida" size="small" class="w-100" @change="updateUnidadMedida($event,'unidad_medida')">
                        <el-option
                        v-for="item in unidades"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.nombre"
                        />
                    </el-select>
                </div>
                <div class="col-3">
                    <small class="pl-3 text-general">
                        Vender por
                    </small>
                    <el-select v-model="venderPor" size="small" class="w-100" @change="updateFormaVenta($event,'vender_por', true)">
                        <el-option label="Unidad" :value="1" />
                        <el-option label="Fracción" :value="2" />
                    </el-select>
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-3 pl-0">
                    <small class="pl-3 text-general">
                        Cantidad aumentada click +
                    </small>
                    <el-input-number
                    v-model="productoTemp.cantidad_aumentada"
                    class="w-100" size="small"
                    :precision="venderPor == 1 ? 0 : 2"
                    :min="0" :max="255"
                    controls-position="right"
                    @change="updateGeneral($event,'cantidad_aumentada',true)"
                    />
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-3 pl-0">
                    <small class="pl-3 text-general">
                        Peso volumétrico
                    </small>
                    <el-select v-model="pesoVolumetrico" size="small" class="w-100" @change="updateGeneral($event,'peso_volumetrico')">
                        <el-option
                        v-for="item in volumenes"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.nombre"
                        />
                    </el-select>
                </div>
                <div class="col-3">
                    <small class="pl-3 text-general">
                        Peso (kg)
                    </small>
                    <el-input-number
                    v-model="productoTemp.peso_kg"
                    class="w-100" size="small"
                    :precision="2" :step="0.1" :max="9999"
                    controls-position="right"
                    @change="updateGeneral($event,'peso_kg')"
                    />
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <!-- <div class="col-3 pl-0">
                    <small class="pl-3 text-general">
                        Categoría
                    </small>
                    <el-select v-model="valueCategoria" class="w-100" size="small" placeholder="Select" filterable @change="addCategoria">
                        <el-option-group
                        v-for="(group,key) in categorias"
                        :key="key"
                        :label="group.nombre"
                        >
                            <el-option
                            v-for="(item,key2) in group.subcategorias"
                            :key="key2"
                            :label="item.nombre"
                            :value="item.id"
                            :disabled="item.disabled"
                            />
                        </el-option-group>
                    </el-select>
                    <div class="row mx-0 mt-2">
                        <div v-for="(categoria,key) in categoriasSeleccionadas" :key="key" class="mn-pill bg-general3 text-white px-2 mb-2 mr-2">
                            <span class="f-12">{{ categoria }}</span>
                            <i class="icon-cancel f-15" @click="deleteCategoria(categoria)" />
                        </div>
                    </div>
                </div> -->
                <div class="col-3 pl-0">
                    <small class="pl-3 text-general">
                        Etiquetas
                    </small>
                    <el-select v-model="valueEtiqueta" size="small" class="w-100" @change="addEtiqueta">
                        <el-option
                        v-for="item in etiquetas"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.nombre"
                        />
                    </el-select>
                    <div class="row mx-0 mt-2">
                        <div v-for="(etiqueta,key) in etiquetasSeleccionadas" :key="key" class="mn-pill bg-general3 text-white px-2 mb-2 mr-2">
                            <span class="f-12">{{ etiqueta }}</span>
                            <i class="icon-cancel f-15" @click="deleteEtiqueta(etiqueta)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white pb-3 mt-4 br-12 pl-5 pt-5" style="box-shadow: 0px 1px 4px #00000014;">
            <p class="text-general f-17 f-600 mb-3">
                Datos para el cedis
            </p>
            <div class="row mx-0 mb-3">
                <div class="col-3 pl-0">
                    <small class="pl-3 text-general">
                        Precio venta
                    </small>
                    <div class="d-middle">
                        <money v-model="precioVenta" class="input-money" v-bind="money" @input="newValor($event, 'precio_unidad')" />
                        <span class="border p-1 br-r-10 bg-gris f-600" style="margin-left: -8px; width: 50px; height:32px;">{{ calcularSiglaActual(idCedis) }}</span>
                    </div>
                </div>
                <div class="col-3">
                    <small class="pl-3 text-general">
                        Valor del costo
                    </small>
                    <div class="d-middle">
                        <money v-model="constoUnitario" class="input-money" v-bind="money" @input="newValor($event, 'costo_unidad')" />
                        <span class="border p-1 br-r-10 bg-gris f-600" style="margin-left: -8px; width: 50px; height:32px;">{{ calcularSiglaActual(idCedis) }}</span>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mb-3">
                <div class="col-3 pl-0">
                    <small class="pl-3 text-general">
                        Stock disponible
                    </small>
                    <el-input-number v-model="productoTemp.stock_disponible" class="w-100" size="small" :min="0" controls-position="right" @change="updateGeneral($event,'stock_disponible')" />
                </div>
                <div class="col-3 d-middle mt-3">
                    <el-checkbox v-model="valueVss" class="check-green" true-label="Si" false-label="No" @change="updateGeneral($event,'venta_sin_stock')" />
                    <span class="mx-2 text-general f-15">Permite vender sin stock</span>
                </div>
            </div>
            <div class="row mx-0 mb-3">
                <div class="col-3 pl-0">
                    <small class="pl-3 text-general">
                        Cantidad mínima de Venta
                    </small>
                    <el-input-number v-model="productoTemp.cantidad_minima" class="w-100" size="small" :min="0" :max="99" controls-position="right" @change="updateGeneral($event,'cantidad_minima')" />
                </div>
                <div class="col-3">
                    <small class="pl-3 text-general">
                        Cantidad máxima de venta
                    </small>
                    <el-input-number v-model="productoTemp.cantidad_maxima" class="w-100" size="small" :min="0" :max="65000" controls-position="right" @change="updateGeneral($event,'cantidad_maxima')" />
                </div>
            </div>
            <div class="row mx-0 mb-3">
                <div class="col-3 pl-0">
                    <small class="pl-3 text-general">
                        Stock minimo CEDIS
                    </small>
                    <el-input-number v-model="productoTemp.stock_minimo_cedis" class="w-100" size="small" :min="0" :max="65000" controls-position="right" @change="updateGeneral($event,'stock_minimo_cedis')" />
                </div>
                <div class="col-3">
                    <small class="pl-3 text-general">
                        Stock minimo {{ $config.vendedor }}
                    </small>
                    <el-input-number v-model="productoTemp.stock_minimo_tienda" class="w-100" size="small" :min="0" :max="65000" controls-position="right" @change="updateGeneral($event,'stock_minimo_tienda')" />
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-3 pl-0">
                    <small class="pl-3 text-general">
                        Utiliza inventario proveedor
                    </small>
                    <el-select v-model="valueInventario" size="small" class="w-100" @change="updateGeneral($event,'inventario_proveedor')">
                        <el-option label="Si" value="Si" />
                        <el-option label="No" value="No" />
                    </el-select>
                </div>
                <div class="col-3">
                    <small class="pl-3 text-general">
                        Proveedor
                    </small>
                    <el-select v-model="valueProveedor" size="small" class="w-100" clearable @change="updateGeneral($event,'proveedor')">
                        <el-option
                        v-for="item in proveedores"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.nombre"
                        />
                    </el-select>
                </div>
            </div>
            <div class="row mx-0 mt-4 mb-3">
                <div class="col-12 mt-2">
                    <p class="text-general f-16 mb-2">Impuestos</p>
                    <el-radio-group v-model="valueImpuesto" @change="updateImpuesto($event,'impuesto')">
                        <el-radio v-for="(i,key) in impuestos" :key="key" :label="i.nombre" />
                    </el-radio-group>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {Money} from 'v-money'
import Productos from '~/services/productos/productosAdmin'
export default {
    components:{ Money },
    data(){
        return {
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            unidadMedida: '',
            venderPor: '',
            pesoVolumetrico: '',
            categoriasSeleccionadas:[],
            valueCategoria:null,

            etiquetasSeleccionadas:[],
            valueEtiqueta:null,

            precioVenta: 0,
            constoUnitario: 0,

            valueImpuesto:'',
            valueInventario:"No",

            valueProveedor:null,
            valueVss:"No",


            categorias:[],
            etiquetas:[],
            unidades:[],
            volumenes:[],
            proveedores:[],
            impuestos:[],

        }
    },
    computed:{
        idCedis(){
            return Number(this.$route.params.id_cedis)
        },
        productoTemp(){
            return this.$route.params.producto
        },
        decimales(){
            return this.getDecimales(this.idCedis)
        }
    },
    watch:{
        categoriasSeleccionadas(value){
            console.log(value);
        }
    },
    beforeCreate(){
        if(!this.$route.params.producto){
            this.$router.back()
        }
    },
    mounted(){
        this.datosBasicos()
        this.getImpuetsosAndPvs()
    },
    methods:{
        async datosBasicos(){
            try {

                const {data} = await Productos.selects_admin()
                this.categorias =  data.categorias
                this.etiquetas =  data.etiquetas
                this.unidades =  data.unidades
                this.volumenes =  data.volumenes

                this.setUnidadMedida()
                this.setVenderPor()
                this.setVolumen()
                //this.setCategorias()
                this.setEtiquetas()
                this.setPrecioAndCosto()
                this.setVentaSinStock()

            } catch (e){
                this.error_catch(e)
            }
        },
        async getImpuetsosAndPvs(){
            try {

                const {data} = await Productos.getImpuetsosAndPvs(this.idCedis)
                this.impuestos = data.impuestos
                this.impuestos.unshift({nombre:"Sin impuestos"})

                this.proveedores = data.proveedores

                this.setImpuesto()
                this.setProveedor()


            } catch (e){
                this.error_catch(e)
            }
        },
        setUnidadMedida(){
            const existe = this.productoTemp.unidad_medida.existe
            const vacio = this.productoTemp.unidad_medida.vacio

            if(existe == false || vacio){
                this.unidadMedida = null
            }else{
                this.unidadMedida = this.productoTemp.unidad_medida.unidad_medida + ' - ' + this.productoTemp.unidad_medida.sigla
            }

        },
        setVenderPor(){
            if(this.productoTemp.vender_por.mal_escrito){
                this.venderPor = null
            }else{
                const res = this.productoTemp.vender_por.vender_por
                const opciones1 = ['Unidad','unidad']
                if(opciones1.includes(res)){
                    this.venderPor = 1
                    return
                }
                const opciones2 = ['Fracción','Fraccion','fracción','fraccion']
                if(opciones2.includes(res)){
                    this.venderPor = 2
                    return
                }
            }
            this.venderPor = null

        },
        setVolumen(){
            const opciones = _.map(this.volumenes, (v) =>{
                return this.removeAcentos(v.nombre)
            })

            const res = this.productoTemp.peso_volumetrico.peso_volumetrico
            if(res === null) return
            const key = opciones.indexOf(this.removeAcentos(res))
            if( key > -1){
                this.pesoVolumetrico = this.volumenes[key].nombre
            }else{
                this.pesoVolumetrico = ''
            }
        },
        setCategorias(){
            return 'Pendiente refactorizar'
            this.categoriasSeleccionadas= []

            const opciones = _.map(this.categorias, (c) =>{
                return _.map(c.subcategorias,(sub) => {
                    return this.removeAcentos(sub.nombre)
                })
            })

            const cat1 = this.productoTemp.categoria1.subcategoria
            const nueva1 = this.productoTemp.categoria1.nueva_subcategoria

            if(cat1 !== null && !nueva1){

                const key1 = opciones.indexOf(this.removeAcentos(cat1))

                if(key1 > -1){
                    this.categoriasSeleccionadas.push(opciones[key1].nombre)
                }
            }else if(cat1 !== null && nueva1){
                this.categoriasSeleccionadas.push(cat1)
            }

            // const cat2 = this.productoTemp.categoria_2.categoria_2
            // const nueva2 = this.productoTemp.categoria_2.nuevo
            // if(cat2 !== null && !nueva2){
            //     const key2 = opciones.indexOf(this.removeAcentos(cat2))

            //     if(key2 > -1){
            //         this.categoriasSeleccionadas.push(this.categorias[key2].nombre)
            //     }
            // }else if(cat2 !== null && nueva2){
            //     this.categoriasSeleccionadas.push(cat2)
            // }

            // const cat3 = this.productoTemp.categoria_3.categoria_3
            // const nueva3 = this.productoTemp.categoria_3.nuevo
            // if(cat3 !== null && !nueva3){
            //     const key3 = opciones.indexOf(this.removeAcentos(cat3))

            //     if(key3 > -1){
            //         this.categoriasSeleccionadas.push(this.categorias[key3].nombre)
            //     }
            // }else if(cat3 !== null && nueva3){
            //     this.categoriasSeleccionadas.push(cat3)
            // }


            // const cat4 = this.productoTemp.categoria_4.categoria_4
            // const nueva4 = this.productoTemp.categoria_4.nuevo
            // if(cat4 !== null && !nueva4){
            //     const key4 = opciones.indexOf(this.removeAcentos(cat4))

            //     if(key4 > -1){
            //         this.categoriasSeleccionadas.push(this.categorias[key4].nombre)
            //     }
            // }else if(cat4 !== null && nueva4){
            //     this.categoriasSeleccionadas.push(cat4)
            // }
        },
        setEtiquetas(){

            this.etiquetasSeleccionadas = []

            const opciones = _.map(this.etiquetas, (e) =>{
                return this.removeAcentos(e.nombre)
            })

            const eti1 = this.productoTemp.etiqueta_1.etiqueta_1
            const nueva1 = this.productoTemp.etiqueta_1.nuevo
            if(eti1 !== null && !nueva1){
                const key1 = opciones.indexOf(this.removeAcentos(eti1))
                if(key1 > -1){
                    this.etiquetasSeleccionadas.push(this.etiquetas[key1].nombre)
                }
            }else if(eti1 !== null && nueva1){
                this.etiquetasSeleccionadas.push(eti1)
            }

            const eti2 = this.productoTemp.etiqueta_2.etiqueta_2
            const nueva2 = this.productoTemp.etiqueta_2.nuevo

            if(eti2 !== null && !nueva2){
                const key2 = opciones.indexOf(this.removeAcentos(eti2))

                if(key2 > -1){
                    this.etiquetasSeleccionadas.push(this.etiquetas[key2].nombre)
                }

            }else if(eti2 !== null && nueva2){
                this.etiquetasSeleccionadas.push(eti2)
            }

            const eti3 = this.productoTemp.etiqueta_3.etiqueta_3
            const nueva3 = this.productoTemp.etiqueta_3.nuevo
            if(eti3 !== null && !nueva3){

                const key3 = opciones.indexOf(this.removeAcentos(eti3))

                if(key3 > -1){
                    this.etiquetasSeleccionadas.push(this.etiquetas[key3].nombre)
                }
            }else if(eti3 !== null && nueva3){
                this.etiquetasSeleccionadas.push(eti3)
            }
        },
        setPrecioAndCosto(){
            this.money.precision = this.decimales
            this.precioVenta = parseFloat(this.productoTemp.precio_unidad)
            this.constoUnitario = parseFloat(this.productoTemp.costo_unidad)
        },
        setImpuesto(){
            const opciones = _.map(this.impuestos, (i) =>{
                return this.removeAcentos(i.nombre)
            })

            const impuesto = this.productoTemp.impuesto.impuesto
            const existe = this.productoTemp.impuesto.existe
            if(impuesto != null && existe){
                const key = opciones.indexOf(this.removeAcentos(impuesto))
                if(key > -1){
                    this.valueImpuesto = this.impuestos[key].nombre
                }
            }else{
                this.valueImpuesto = "Sin impuestos"
            }
        },
        setProveedor(){
            const inventarioPvs = this.productoTemp.inventario_proveedor.inventario_proveedor
            const malEscrito = this.productoTemp.inventario_proveedor.mal_escrito

            if(malEscrito == false ){
                if(inventarioPvs == 'Si' || inventarioPvs == 'si'){
                    this.valueInventario = "Si"
                }

                if(inventarioPvs == 'No' || inventarioPvs == 'no'){
                    this.valueInventario = "No"
                }
            }else{
                this.valueInventario = "No"
            }

            const proveedor = this.productoTemp.proveedor.proveedor
            const existe = this.productoTemp.proveedor.existe

            const opciones = _.map(this.proveedores, (p) =>{
                return this.removeAcentos(p.nombre)
            })

            if(proveedor != null && existe){
                const key = opciones.indexOf(this.removeAcentos(proveedor))
                if(key > -1){
                    this.valueProveedor = this.proveedores[key].nombre
                }
            }else{
                this.valueProveedor = null
            }



        },
        setVentaSinStock(){
            const ventaSS = this.productoTemp.venta_sin_stock.venta_sin_stock
            const malEscrito = this.productoTemp.venta_sin_stock.mal_escrito
            const vacio = this.productoTemp.venta_sin_stock.vacio

            if(malEscrito == false && vacio == false){
                if(ventaSS == 'Si' || ventaSS == 'si'){
                    this.valueVss = "Si"
                }

                if(ventaSS == 'No' || ventaSS == 'no'){
                    this.valueVss = "No"
                }
            }else{
                this.valueVss = "No"
            }
        },
        async updateGeneral(value,columna,requerido = false){
            console.log(value,columna);
            try {
                if(requerido && (value == '' || value == null)){
                    return this.notificacion('Advertencia','El campo es requerido','warning')
                }

                const form = {
                    idTemp:this.productoTemp.id,
                    value,
                    columna
                }

                const {data} = await Productos.updateRowImportar(this.idCedis,form)
                this.notificacion('','Fila actualizada correctamente','success')

            } catch (error){
                this.error_catch(error)
            }
        },
        updateUnidadMedida(value,columna){
            const unidad = this.unidades.find(u => u.nombre == value)
            const nombre = unidad.nombre.split("-",1).shift().trim()

            this.updateGeneral(nombre,columna,true)
        },
        updateFormaVenta(value,columna){
            if(value == 1){
                this.updateGeneral('Unidad',columna,true)
                return
            }
            if(value == 2){
                this.updateGeneral('Fracción',columna,true)
                return
            }
        },
        addCategoria(value){

            if(this.categoriasSeleccionadas.length == 4){
                this.notificacion('Advertencia','Ha llegado al limite de categorias posibles','warning')
                return
            }

            this.categoriasSeleccionadas.push(value)
            this.valueCategoria = null
            this.categoriasSeleccionadas = _.uniq(this.categoriasSeleccionadas)

            this.updateCategorias()
        },
        deleteCategoria(value){
            this.categoriasSeleccionadas = _.remove(this.categoriasSeleccionadas, function(n){
                return n != value;
            });

            this.updateCategorias()
        },
        async updateCategorias(){
            try {
                const form = {
                    idTemp: this.productoTemp.id,
                    categorias: this.categoriasSeleccionadas
                }

                const {data} = await Productos.updateCategorias(this.idCedis, form)

            } catch (error){
                this.error_catch(error)
            }
        },
        addEtiqueta(value){

            if(this.etiquetasSeleccionadas.length == 3){
                this.notificacion('Advertencia','Ha llegado al limite de etiquetas posibles','warning')
                return
            }

            this.etiquetasSeleccionadas.push(value)
            this.valueEtiqueta = null
            this.etiquetasSeleccionadas = _.uniq(this.etiquetasSeleccionadas)

            this.updateEtiquetas()
        },
        deleteEtiqueta(value){
            this.etiquetasSeleccionadas = _.remove(this.etiquetasSeleccionadas, function(n){
                return n != value;
            });

            this.updateEtiquetas()
        },
        async updateEtiquetas(){
            try {
                const form = {
                    idTemp: this.productoTemp.id,
                    etiquetas: this.etiquetasSeleccionadas
                }

                const {data} = await Productos.updateEtiquetas(this.idCedis, form)

            } catch (error){
                this.error_catch(error)
            }
        },
        newValor(value, column){
            const change = () => {
                this.updateGeneral(value,column,true)
            }
            this.delay(change)
        },
        updateImpuesto(value, columna){
            if(value === "Sin impuestos"){
                this.updateGeneral(null,columna)
                return
            }
            this.updateGeneral(value,columna)
        }





    }

}
</script>
